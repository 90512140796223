<template>
  <v-btn>h</v-btn>
<!--  <v-container class="grey lighten-5">-->
<!--    <v-row no-gutters>-->
<!--      <v-col-->
<!--        cols="12"-->
<!--        sm="4"-->
<!--      >-->
<!--        <v-card-->
<!--          class="pa-2"-->
<!--          outlined-->
<!--          tile-->
<!--        >-->
<!--          <v-card-title>H1 Draggable table with TR</v-card-title>-->
<!--          <table>-->
<!--            <tbody id="sortable">-->
<!--            <tr-->
<!--              v-for="(item, index) in items"-->
<!--              :data-id="index"-->
<!--            >-->
<!--              <td>{{ item }}</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col-->
<!--        cols="12"-->
<!--        sm="4"-->
<!--      >-->
<!--        <v-card-->
<!--          class="pa-2"-->
<!--          outlined-->
<!--          tile-->
<!--        >-->
<!--          <v-card-title>H2 Draggable list</v-card-title>-->
<!--          <div-->
<!--            id="sortable2"-->
<!--            class="row sort-wrap"-->
<!--          >-->
<!--            <div-->
<!--              v-for="(item, index) in items2"-->
<!--              class="col-xs-6 col-md-3 thumbnail"-->
<!--              :data-id="index"-->
<!--            >-->
<!--              <img-->
<!--                :src="item"-->
<!--                alt=""-->
<!--                class="img-responsive"-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-for="item in sortedItems2">-->
<!--            {{ item }}-->
<!--          </div>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col-->
<!--        cols="12"-->
<!--        sm="4"-->
<!--      >-->
<!--        <v-card-->
<!--          class="pa-2"-->
<!--          outlined-->
<!--          tile-->
<!--        >-->
<!--          <v-card-title>H2 Draggable list</v-card-title>-->
<!--          <v-btn @click="printDemo">-->
<!--            Print demo-->
<!--          </v-btn>-->
<!--          <vuetify-draggable-treeview-->
<!--            v-model="treeItems"-->
<!--            :group="treeGroup"-->
<!--          />-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->
</template>

<script>
  import draggable from 'vuedraggable'
  import Sortable from 'sortablejs'
  // import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
  import VuetifyDraggableTreeview from '@/views/blitz/components/susan/DragableTreeView'

  import * as docxtemplater from 'docxtemplater'
  // import * as inspect from 'docxtemplater/js/inspect-module'
  import * as PizZip from 'pizzip'
  import * as pizzipUtils from 'pizzip/utils'
  import * as FileSaver from 'file-saver'

  // https://blog.csdn.net/meimeilive/article/details/103150412
  // https://github.com/opendocx/opendocx/tree/master/test/templates
  // https://codepen.io/lgunko/pen/QGpdVO
  // https://blog.risingstack.com/pdf-from-html-node-js-puppeteer/
  // https://markus.oberlehner.net/blog/creating-pdf-invoices-with-airtable-vue-and-puppeteer/
  // https://stackoverflow.com/questions/51503633/click-and-edit-text-input-with-vue
  // https://medium.com/javascript-in-plain-english/render-dynamically-a-docx-file-with-javascript-daaed816fcb8
  // https://dev.to/sintj_/render-dynamically-a-docx-file-with-javascript-3mef

  export default {
    name: 'Simple',
    display: 'Simple',
    order: 0,
    // components: {
    //   draggable,
    //   VuetifyDraggableTreeview,
    // },
    data () {
      return {
        publicPath: process.env.BASE_URL,
        items: [
          'http://placehold.it/200X300?text=image1',
          'http://placehold.it/200X300?text=image2',
          'http://placehold.it/200X300?text=image3',
          'http://placehold.it/200X300?text=image4',
        ],
        items2: [
          'http://placehold.it/200X300?text=image1',
          'http://placehold.it/200X300?text=image2',
          'http://placehold.it/200X300?text=image3',
          'http://placehold.it/200X300?text=image4',
        ],
        order: null,
        order2: null,
        treeGroup: {
          name: 'costcentre',
          pull: false, // Do not allow items to be pull from this list
          put: ['datatable', 'costcentreUnallocated'], // 'clone' ie allow data to be put from datatable
        },
        treeItems: [
          {
            id: 1,
            name: 'Central League',
            children: [
              { id: 101, name: 'Dragons' },
              { id: 102, name: 'Tigers' },
            ],
          },
          {
            id: 2,
            name: 'Pacific League',
            children: [
              {
                id: 201,
                name: 'Lions',
                children: [
                  { id: 203, name: 'Lions Minor' },
                  {
                    id: 204,
                    name: 'Lions third',
                    children: [{ id: 2041, name: 'Lions Minor Second' }],
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    computed: {
      sortedItems () {
        if (!this.order) {
          return this.items
        }
        return this.order.map((i) => this.items[i])
      },
      sortedItems2 () {
        if (!this.order2) {
          return this.items2
        }
        return this.order2.map((i) => this.items2[i])
      },
    },
    mounted () {
      this.$nextTick(() => {
        const sortable = Sortable.create(document.getElementById('sortable'), {
          group: {
            name: 'datatable',
          },
          animation: 200,
          onUpdate: () => {
            this.order = sortable.toArray()
          },
          // Called when creating a clone of element
          onClone: function (/** Event */evt) {
            console.log('H1 onClone ')
            console.log(evt.clone)
            var origEl = evt.item
            var cloneEl = evt.clone
            evt.clone = { id: 2000, textValue: '111' }
          },

          setData: (/** DataTransfer */dataTransfer, /** HTMLElement */dragEl) => {
            console.log('H1 setData ' + dragEl.textContent)
            dataTransfer.setData('text/plain', '555') // `dataTransfer` object of HTML5 DragEvent
          },

        })
        const sortable2 = Sortable.create(document.getElementById('sortable2'), {
          group: {
            name: 'datatable',
          },
          animation: 200,
          onUpdate: () => {
            console.log('sortable2 array is ')
            console.log(sortable2.toArray())
            this.order2 = sortable2.toArray()
          },
          // Element is dropped into the list from another list
          onAdd: (/** Event */ evt) => {
            // same properties as onEnd
            console.log('onAdd ')
            console.log(evt)
            console.log('onAdd to')
            console.log(evt.to)
            let clonedObject = Sortable.utils.clone(evt.originalEvent.toElement.parentElement)
            console.log('onAdd cloned')
            console.log(clonedObject)
            //
            // now manipulate
            // remove child nodes - this is UI appearance logic...
            evt.to.removeChild(evt.to.childNodes[evt.newIndex])
            // evt.to.replaceChild(clonedObject, evt.to.childNodes[0])
            console.log('Data Transfer text data is ' + evt.originalEvent.dataTransfer.getData('text'))
            // return false
          },
          // Called when creating a clone of element
          onClone: function (/** Event */evt) {
            console.log('H2 onClone ' + evt.clone)
            // let origEl = evt.item
            // let cloneEl = evt.clone
          },
          setData: (/** DataTransfer */dataTransfer, /** HTMLElement */dragEl) => {
            console.log('H2 setData ' + dragEl.textContent)
            dataTransfer.setData('Text', dragEl.textContent) // `dataTransfer` object of HTML5 DragEvent
          },

        })
      })
    },
    // mounted () {
    // let table = document.querySelector('.v-data-table tbody')
    // const _self = this
    // Sortable.create(table, {
    //   handle: '.handle', // Use handle so user can select text
    //   group: {
    //     name: 'datatable',
    //     pull: 'clone',
    //     put: false, // Do not allow items to be put into this list
    //   },
    //   onMove: this.checkMoveOnDataTableView,
    //   setData: this.setDragDataFromDataTable,
    //   onStart: this.dataTableOnStart,
    //   onEnd: this.dataTableOnEnd,
    //   onClone: this.onClone,
    //   clone: this.cloneDog,
    // })
    methods: {
      loadFile (url, callback) {
        pizzipUtils.getBinaryContent(url, callback)
      },
      base64DataURLToArrayBuffer (dataURL) {
        const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/
        if (!base64Regex.test(dataURL)) {
          return false
        }
        const stringBase64 = dataURL.replace(base64Regex, '')
        let binaryString
        if (typeof window !== 'undefined') {
          binaryString = window.atob(stringBase64)
        } else {
          binaryString = Buffer.from(stringBase64, 'base64').toString('binary')
        }
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          const ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes.buffer
      },
      printDemo () {
        let sampleDocPath = this.publicPath + '/template/test3-template.docx'
        console.log('printDemo sampleDocPath is ' + sampleDocPath)
        this.loadFile(sampleDocPath, function (error, content) {
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          // let zip = new JSzip(content);
          let doc = new docxtemplater().loadZip(zip)
          doc.setData({
            customer_name: 'ARTC',
            account_name: 'Woodside account',
            first_name: 'John',
            last_name: 'Doe',
            phone: '0652455478',
            description: 'New Website',
          })
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render()
          } catch (error) {
            // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)

            const e = {
              message: error.message,
              name: error.name,
              stack: error.stack,
              properties: error.properties,
            }
            console.log(JSON.stringify({ error: e }))
            throw error
          }

          let out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }) // Output the document using Data-URI

          FileSaver.saveAs(out, 'output.docx')
        })
      },
    },
  }
  /***
   *
   footerTemplate: `
   <style>
   div {
                    width: 100%;
                    padding-right: 1.5cm;
                    padding-bottom: 0.25cm;
                    font-family: Arial;
                    font-size: 8pt;
                    text-align: right;
                    color: #666;
                  }
   </style>
   <div>
   Page <span class="pageNumber"></span> / <span class="totalPages"></span>
   </div>
   `,
   // HTML in the header and footer
   // templates is unstyled by default.
   // You have to use inline styles for
   // styling.
   // headerTemplate: ' ',

   */
</script>
<style>

</style>
