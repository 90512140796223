var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`v-treeview-node v-treeview-node--click ${
      _vm.hasChildren ? '' : 'v-treeview-node--leaf'
    }`,attrs:{"data-id":_vm.value.id}},[_c('div',{staticClass:"v-treeview-node__root",on:{"click":function($event){_vm.open = !_vm.open}}},[(_vm.hasChildren)?_c('i',{staticClass:"v-icon notranslate v-treeview-node__toggle v-icon--link mdi mdi-menu-down",class:{
        'v-treeview-node__toggle--open': _vm.open,
        'theme--dark': _vm.isDark,
        'theme--light': !_vm.isDark
      },attrs:{"role":"button"}}):_vm._e(),_vm._t("prepend",null,null,{ item: _vm.value, open: _vm.open }),_c('div',{staticClass:"v-treeview-node__label"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.value.name)+" ")]},null,{ item: _vm.value, open: _vm.open })],2),_vm._t("append",null,null,{ item: _vm.value })],2),(_vm.open)?_c('div',{staticClass:"v-treeview-node__children v-treeview-node__children__draggable"},[_c('draggable',{attrs:{"group":_vm.group,"value":_vm.value.children,"ghost-class":"ghost","component-data":_vm.getComponentData()},on:{"input":_vm.updateValue,"add":_vm.treeNodeAdded,"start":_vm.treeViewNodeStart,"drop":_vm.dropOnTreeNode}},_vm._l((_vm.value.children),function(child){return _c('treeview-node',{key:child.id,attrs:{"group":_vm.group,"value":child},on:{"input":_vm.updateChildValue},scopedSlots:_vm._u([{key:"label",fn:function({ item, open }){return [_vm._t("label",null,null,{ item, open })]}},{key:"prepend",fn:function({ item, open }){return [_vm._t("prepend",null,null,{ item, open })]}},{key:"append",fn:function({ item }){return [_vm._t("append",null,null,{ item })]}}],null,true)})}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }