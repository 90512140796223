<template>
  <draggable
    :value="localValue"
    class="v-treeview v-treeview-draggable"
    :class="themeClassName"
    ghost-class="ghost"
    :clone="treeClone"
    :move="treeNodeMove"
    @add="treeViewAdded"
    @end="treeNodeDragEnded"
    @input="updateValue"
    @change="treeNodeChanged"
    @drop="dropOnTree"
  >
    <draggable-tree-view-node
      v-for="item in value"
      :key="item.id"
      :group="group"
      :value="item"
      @input="updateItem"
      @nodeDragStart="treeViewNodeStart"
    >
      <template v-slot:prepend="{ item, open }">
        <slot
          name="prepend"
          v-bind="{ item, open }"
        />
      </template>
      <template v-slot:label="{ item, open }">
        <slot
          name="label"
          v-bind="{ item, open }"
        />
      </template>
      <template v-slot:append="{ item, open }">
        <slot
          name="append"
          v-bind="{ item, open }"
        />
      </template>
    </draggable-tree-view-node>
  </draggable>
</template>

<script >
  import draggable from 'vuedraggable'
  import DraggableTreeViewNode from './DragableTreeViewNode'

  export default {
    name: 'DragableTreeViewView',
    components: {
      draggable,
      DraggableTreeViewNode,
    },
    props: {
      value: {
        type: Array,
      },
      group: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        localValue: [...this.value],
      }
    },
    computed: {
      themeClassName () {
        return this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
      },
    },
    watch: {
      value (value) {
        this.localValue = [...value]
      },
    },
    methods: {
      treeViewNodeStart: function (evt) {
        console.log('treeViewStart here')
        console.log(evt)
        this.$emit('nodeDragStart', evt)
      },
      treeViewAdded: function (evt) {
        console.log('treeViewAdded here ' + evt.type)
        console.log(evt.clone)
      },
      treeNodeDragEnded: function (evt) {
        console.log('treeNodeDragEnded here')
        console.log(evt)
      },
      treeNodeMove: function (evt) {
        console.log('treeNodeMove here')
        console.log(evt.draggedContext)
      },
      treeNodeChanged: function (evt) {
        console.log('treeNodeChanged here')
        console.log(evt)
      },
      dropOnTree: function (value) {
        console.log('dropOnTree here')
        console.log(value)
      },
      treeClone: function (value) {
        console.log('treeClone here')
        console.log(value)
      },
      updateValue (value) {
        console.log('tree updateValue here')
        console.log(value)
        this.localValue = value
        this.$emit('input', this.localValue)
      },
      updateItem (itemValue) {
        console.log('tree updateItem itemValue is ')
        console.log(itemValue)
        const index = this.localValue.findIndex(v => v.id === itemValue.id)
        this.$set(this.localValue, index, itemValue)
        this.$emit('input', this.localValue)
      },
    },
  }
</script>
