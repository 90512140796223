<template>
  <div
    :class="
      `v-treeview-node v-treeview-node--click ${
        hasChildren ? '' : 'v-treeview-node--leaf'
      }`
    "
    :data-id="value.id"
  >
    <div
      class="v-treeview-node__root"
      @click="open = !open"
    >
      <i
        v-if="hasChildren"
        role="button"
        class="v-icon notranslate v-treeview-node__toggle v-icon--link mdi mdi-menu-down"
        :class="{
          'v-treeview-node__toggle--open': open,
          'theme--dark': isDark,
          'theme--light': !isDark
        }"
      />
      <slot
        name="prepend"
        v-bind="{ item: value, open }"
      />
      <div class="v-treeview-node__label">
        <slot
          name="label"
          v-bind="{ item: value, open }"
        >
          {{ value.name }}
        </slot>
      </div>
      <slot
        name="append"
        v-bind="{ item: value }"
      />
    </div>
    <div
      v-if="open"
      class="v-treeview-node__children v-treeview-node__children__draggable"
    >
      <draggable
        :group="group"
        :value="value.children"
        ghost-class="ghost"
        :component-data="getComponentData()"
        @input="updateValue"
        @add="treeNodeAdded"
        @start="treeViewNodeStart"
        @drop="dropOnTreeNode"
      >
        <treeview-node
          v-for="child in value.children"
          :key="child.id"
          :group="group"
          :value="child"
          @input="updateChildValue"
        >
          <template v-slot:label="{ item, open }">
            <slot
              name="label"
              v-bind="{ item, open }"
            />
          </template>
          <template v-slot:prepend="{ item, open }">
            <slot
              name="prepend"
              v-bind="{ item, open }"
            />
          </template>
          <template v-slot:append="{ item }">
            <slot
              name="append"
              v-bind="{ item }"
            />
          </template>
        </treeview-node>
      </draggable>
    </div>
  </div>
</template>

<script >
  import Draggable from 'vuedraggable'

  // type TreeItem = {
  //   id: number;
  //   name: string;
  //   children: TreeItem[];
  // };

  export default {
    name: 'TreeviewNode',
    components: {
      Draggable,
    },
    props: {
      value: {
        type: Object,
        default: () => ({
          id: 0,
          name: '',
          children: [],
        }),
      },
      root: {
        type: Boolean,
        default: () => false,
      },
      group: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        open: false,
        localValue: { ...this.value }, // as TreeItem
      }
    },
    computed: {
      hasChildren () {
        return this.value.children != null && this.value.children.length > 0
      },
      isDark () {
        return this.$vuetify.theme.isDark
      },
    },
    watch: {
      value (value) {
        this.localValue = { ...value }
      },
    },
    methods: {
      treeNodeAdded: function (evt) {
        console.log('treeNodeAdded here event type' + evt.type)
        console.log(evt.to)
        if (evt.type === 'add') {
          console.log('treeNodeAdded new Index node')
          console.log(evt.to.childNodes[evt.newIndex])
          console.log('treeNodeAdded old Index node')
          const parentElement = evt.to.children[evt.oldIndex]
          console.log(parentElement)
          console.log(parentElement.dataset.id)
          evt.to.removeChild(evt.to.childNodes[evt.newIndex])
          console.log('Data Transfer text data is ' + evt.originalEvent.dataTransfer.getData('text'))
        }
      },
      handleChange () {
        console.log('handleChange called changed')
      },
      inputChanged (value) {
        this.activeNames = value
      },
      getComponentData () {
        console.log('getComponentData called')
        return {
          on: {
            change: this.handleChange,
            input: this.inputChanged,
          },
          attrs: {
            wrap: true,
          },
          props: {
            value: this.activeNames,
          },
        }
      },
      dropOnTreeNode: function (value) {
        console.log('dropOnTreeNode here!!!')
        console.log(value)
      },
      treeViewNodeStart: function (evt) {
        console.log('treeViewNodeStart here')
        console.log(evt)
        this.$emit('nodeDragStart', evt)
      },
      updateValue (value) { // TreeItem
        this.localValue.children = [...value]
        this.$emit('input', this.localValue)
      },
      updateChildValue (value) { // TreeItem
        const index = this.localValue.children.findIndex(c => c.id === value.id)
        this.$set(this.localValue.children, index, value)
        this.$emit('input', this.localValue)
      },
    },
  }
</script>
